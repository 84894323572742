// ScrollLock
const bodyScrollLock = require("body-scroll-lock");

let isOpen = true;
function toggleScrollLock() {
  const disableBodyScroll = bodyScrollLock.disableBodyScroll;
  const enableBodyScroll = bodyScrollLock.enableBodyScroll;

  const targetElement = document.querySelector(".js-header-hamburger-menu");

  if (isOpen) {
    disableBodyScroll(targetElement);
  } else {
    enableBodyScroll(targetElement);
  }

  isOpen = !isOpen;
}

// クリックイベント
document.addEventListener(
  "click",
  function (e) {
    // ハンバーガーメニューをクリックしたときの処理
    if (e.target.classList.contains("js-header-hamburger")) {
      document
        .querySelector(".js-header-hamburger")
        .classList.toggle("is-hamburger-open");
      document
        .querySelector(".js-header-hamburger-menu")
        .classList.toggle("is-hamburger-open");
      document
        .querySelector(".js-header-logo")
        .classList.toggle("is-hamburger-open");
      document
        .querySelector(".js-header-search")
        .classList.toggle("is-hamburger-open");

      toggleScrollLock();
    }

    // ハンバーガーメニュー内のメニューをクリックしたときの処理
    if (e.target.classList.contains("js-hamburger-list-item")) {
      e.target.children[0].classList.toggle("is-hamburger-list-open");
      e.target.classList.toggle("is-hamburger-list-open");
    }

    // サイト内検索
    if (e.target.classList.contains("js-header-search")) {
      e.target.classList.toggle("is-search-open");
      if (e.target.classList.contains("is-search-open")) {
        document.querySelector(".page").classList.add("is-search-open");
      } else {
        document.querySelector(".page").classList.remove("is-search-open");
      }
      toggleScrollLock();
    }
  },
  false
);

// マウスオーバーイベント
document.addEventListener(
  "mouseover",
  function (e) {
    // ヘッダーメニューにマウスを置いたときの処理
    if (e.target.classList.contains("js-header-menu-link")) {
      document.querySelectorAll(".js-header-menu-link").forEach((element) => {
        element.classList.remove("is-open");
      });

      e.target.classList.add("is-open");
      document.querySelector(".page").classList.add("is-header-open");

      document.querySelectorAll(".js-nav-menu").forEach((element) => {
        if (element.dataset.value === e.target.dataset.value) {
          element.classList.add("is-open");
        } else {
          element.classList.remove("is-open");
        }
      });
    }

    // ヘッダーメニューからマウスを離したときの処理
    if (
      (e.pageY < 84 && !e.target.classList.contains("js-header-menu-link")) ||
      e.target.classList.contains("page")
    ) {
      document.querySelector(".page").classList.remove("is-header-open");

      document.querySelectorAll(".js-nav-menu").forEach((element) => {
        element.classList.remove("is-open");
      });

      document.querySelectorAll(".js-header-menu-link").forEach((element) => {
        element.classList.remove("is-open");
      });
    }
  },
  false
);

// tabキーによるfocusin
document.addEventListener("focusin", function (e) {
  function showMegaMenu(targetElm, targetVal) {
    document.querySelectorAll(".js-header-menu-link").forEach((element) => {
      element.classList.remove("is-open");
    });
    targetElm.classList.add("is-open");
    document.querySelector(".page").classList.add("is-header-open");

    document.querySelectorAll(".js-nav-menu").forEach((element) => {
      if (element.dataset.value === targetVal) {
        element.classList.add("is-open");
      } else {
        element.classList.remove("is-open");
      }
    });
  }

  if (e.target.classList.contains("js-header-menu-link")) {
    const currentTarget = e.target;
    const currentValue = e.target.dataset.value;
    showMegaMenu(currentTarget, currentValue);
  }

  const currentNavParent = e.target.closest(".js-nav-menu");
  if (currentNavParent !== null) {
    const currentValue = currentNavParent.dataset.value;
    const currentTarget = document.querySelector(
      `.js-header-menu-link[data-value="${currentValue}"]`
    );
    showMegaMenu(currentTarget, currentValue);
  }

  // ヘッダーメニューからマウスを離したときの処理
  // if (
  //   (e.pageY < 84 && !e.target.classList.contains("js-header-menu-link")) ||
  //   e.target.classList.contains("page")
  // ) {
  //   document.querySelector(".page").classList.remove("is-header-open");

  //   document.querySelectorAll(".js-nav-menu").forEach((element) => {
  //     element.classList.remove("is-open");
  //   });

  //   document.querySelectorAll(".js-header-menu-link").forEach((element) => {
  //     element.classList.remove("is-open");
  //   });
  // }
});

// tabキーによるfocusout
document.addEventListener("focusout", function (e) {
  function hideMegaMenu() {
    document.querySelector(".page").classList.remove("is-header-open");

    document.querySelectorAll(".js-nav-menu").forEach((element) => {
      element.classList.remove("is-open");
    });

    document.querySelectorAll(".js-header-menu-link").forEach((element) => {
      element.classList.remove("is-open");
    });
  }

  const currentNavParent = e.target.closest(".js-nav-menu");
  if (
    e.target.classList.contains("js-header-menu-link") ||
    e.target.classList.contains("page") ||
    currentNavParent !== null
  ) {
    hideMegaMenu();
  }

  // ヘッダーメニューからマウスを離したときの処理
  // if (
  //   (e.pageY < 84 && !e.target.classList.contains("js-header-menu-link")) ||
  //   e.target.classList.contains("page")
  // ) {
  //   document.querySelector(".page").classList.remove("is-header-open");

  //   document.querySelectorAll(".js-nav-menu").forEach((element) => {
  //     element.classList.remove("is-open");
  //   });

  //   document.querySelectorAll(".js-header-menu-link").forEach((element) => {
  //     element.classList.remove("is-open");
  //   });
  // }
});

// HeaderScroll
function headerScroll() {
  const headerDOM = document.querySelector(".js-header");
  const headerHeight = headerDOM.clientHeight;

  if (headerDOM) {
    const body = document.body;
    body.style.paddingTop = `${headerHeight}px`;
  }
}
headerScroll();

document.addEventListener("DOMContentLoaded", () => {
  const pagetopButton = document.querySelector(".js-pagetop");
  function getScrolled() {
    return window.pageYOffset !== undefined
      ? window.pageYOffset
      : document.documentElement.scrollTop;
  }

  window.onscroll = function () {
    getScrolled() > 600
      ? pagetopButton.classList.remove("footer_pagetop_invisible")
      : pagetopButton.classList.add("footer_pagetop_invisible");
  };

  pagetopButton.addEventListener("click", function (e) {
    e.preventDefault();
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  });

  // PC版サイト内検索
  const input_search = document.getElementById("kw");
  input_search.addEventListener("input", function () {
    if (this.value == 0) {
      this.nextElementSibling.classList.remove("is-valid");
    } else {
      this.nextElementSibling.classList.add("is-valid");
    }
  });

  // SP版サイト内検索
  const input_search_sp = document.getElementById("kw-sp");
  input_search_sp.addEventListener("input", function () {
    if (this.value == 0) {
      this.nextElementSibling.classList.remove("is-valid");
    } else {
      this.nextElementSibling.classList.add("is-valid");
    }
  });
});
